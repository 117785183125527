import { defineStore } from 'pinia'

import useActions from './actions'
import { type OnboardingState } from 'types/onboarding'

export const useOnboardingStore = defineStore('onboarding', () => {
  const state: OnboardingState = reactive({
    occupations: {
      data: [],
      isLoading: false
    },
    studyReasons: {
      data: [],
      isLoading: false
    },
    fields: {
      data: [],
      isLoading: false
    },
    topics: {
      data: [],
      isLoading: false
    }
  })

  const actions = useActions({ state })

  return {
    state,
    ...actions
  }
})
