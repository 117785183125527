const useActions = ({ state }) => {
  const { API_URL } = useBaseUrl()
  const { handleErrorInfo } = useCustomFetch()

  const fetchOccupations = async () => {
    state.occupations.isLoading = true
    const { data, error, pending } = await useAsyncData('occupations', () =>
      $fetch(`${API_URL}/occupations`, {
        method: 'GET'
      })
    )

    if (!data.value && error.value) {
      handleErrorInfo(error, 'fetchOccupations')
    } else {
      const newData = useResult(data?.value, 'data', [])
      state.occupations.data =
        newData?.map((item) => {
          item.label = item.name
          item.value = item.name
          return item
        }) ?? []
    }
    state.occupations.isLoading = pending.value ?? false
  }

  const fetchStudyReason = async () => {
    state.studyReasons.isLoading = true
    const { data, error, pending } = await useAsyncData('study-reasons', () =>
      $fetch(`${API_URL}/study-reasons`, {
        method: 'GET'
      })
    )

    if (!data.value && error.value) {
      handleErrorInfo(error, 'fetchStudyReason')
    } else {
      const newData = useResult(data?.value, 'data', [])
      state.studyReasons.data =
        newData?.map((item) => {
          item.label = item.name
          item.value = item.name
          return item
        }) ?? []
      state.studyReasons.isLoading = pending.value ?? false
    }
  }

  const fetchTopics = async () => {
    state.topics.isLoading = true
    const { data, error, pending } = await useAsyncData('topics', () =>
      $fetch(`${API_URL}/topics`, {
        method: 'GET'
      })
    )

    if (!data.value && error.value) {
      handleErrorInfo(error, 'fetchTopics')
    } else {
      const newData = useResult(data?.value, 'data', [])
      state.topics.data =
        newData?.map((item) => {
          item.label = item.name
          item.value = item.name
          return item
        }) ?? []
    }
    state.topics.isLoading = pending.value ?? false
  }

  const fetchFields = async () => {
    state.fields.isLoading = true
    const { data, error, pending } = await useAsyncData('fields', () =>
      $fetch(`${API_URL}/fields`, {
        method: 'GET'
      })
    )

    if (!data.value && error.value) {
      handleErrorInfo(error, 'fetchFields')
    }

    const newData = useResult(data?.value, 'data', [])
    state.fields.data =
      newData?.map((item) => {
        item.label = item.name
        item.value = item.name
        return item
      }) ?? []

    state.fields.isLoading = pending.value ?? false
  }

  return {
    fetchOccupations,
    fetchStudyReason,
    fetchTopics,
    fetchFields
  }
}

export default useActions
